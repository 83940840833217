/* eslint-disable max-len */
import { AlterPlanAction } from './alter-plan-action';

const addPaidFormsDefault: AlterPlanAction = {
  action: 'change',
  ctaText: 'Add Form Tracking',
  planId: 'PLA1fec8fd85ba547a2bac0938e3aeaa0bd',
  trial: false,
  productType: 'forms',
  modalGraphic: 'form-capture-upsell.svg',
  modalGraphicWhiteLabel: 'form-capture-upsell-wl.svg',
  modalTitle: 'Get attribution for web forms',
  modalBodyText:
    'Add the Form Tracking product for form tracking and advanced multi-channel reporting features. Add it to your existing plan for a base price of $50 per month.',
  successAlert: `You've successfully upgraded to Form Tracking. For more information, visit your billing page.`,
  successLinkText: 'Billing.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: false,
  externalLink: false,
};

const tryFormsDefaultSimple: AlterPlanAction = {
  action: 'change',
  planId: 'PLA1fec8fd85ba547a2bac0938e3aeaa0bd',
  trial: true,
  productType: 'forms',
  successAlert: `You’ve successfully upgraded to Form Tracking. You will be billed $50 per month after your free 14-day trial. To make changes to your account, visit`,
  successLinkText: 'Billing.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  externalLink: false,
};

const tryFormsDefault: AlterPlanAction = {
  ...tryFormsDefaultSimple,
  ctaText: 'Start 14-day Free Trial',
  modalCancelEvent: ['Cross Sell', 'Try Forms', 'Cancel'],
  modalConfirmEvent: ['Cross Sell', 'Try Forms', 'Confirm'],
  modalGraphic: 'form-capture-upsell.svg',
  modalGraphicWhiteLabel: 'form-capture-upsell-wl.svg',
  modalTitle: 'Add Form Tracking',
  modalBodyText:
    'Start tracking your web form leads for a holistic picture of your ad performance. The base price of this plan is $50 per month after your trial ends.',
  skipModal: false,
  externalLink: false,
};

const tryFormsWithoutModal = {
  ...tryFormsDefaultSimple,
  successAlert: `You’ve successfully added Form Tracking to your account. See your current plan and active trials on your`,
  successLinkText: 'Billing page.',
  skipModal: true,
};

const addPaidCallsDefault: AlterPlanAction = {
  action: 'change',
  ctaText: 'Confirm',
  planId: 'PLAc656379ae7c74ec6b04268bd5c666be7',
  trial: false,
  productType: 'calls',
  modalGraphic: 'calls-upsell.svg',
  modalGraphicWhiteLabel: 'calls-upsell-wl.svg',
  modalTitle: 'Get attribution for calls and texts',
  modalBodyText:
    'Understand which ad campaigns drive quality inbound calls and text messages. The base price of this plan is $45 per month if you decide to move forward after your trial ends.',
  successAlert: `You've successfully activated calls and texts. If you need to adjust your plan please go to the`,
  successLinkText: 'Pricing Plan page.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: false,
  externalLink: false,
};

const tryCallsDefault: AlterPlanAction = {
  action: 'change',
  ctaText: 'Start 14-day Free Trial',
  planId: 'PLAc656379ae7c74ec6b04268bd5c666be7',
  trial: true,
  productType: 'calls',
  modalCancelEvent: ['Cross Sell', 'Try Calls', 'Cancel'],
  modalConfirmEvent: ['Cross Sell', 'Try Calls', 'Confirm'],
  modalGraphic: 'calls-upsell.svg',
  modalGraphicWhiteLabel: 'calls-upsell-wl.svg',
  modalTitle: 'Get attribution for calls and texts',
  modalBodyText:
    'Understand which ad campaigns drive quality inbound calls and text messages. The base price of this plan is $45 per month if you decide to move forward after your trial ends.',
  successAlert: `You've successfully activated your free Calls Essentials trial. For more information, visit`,
  successLinkText: 'Billing.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: false,
  externalLink: false,
};

const tryCIWithoutModal: AlterPlanAction = {
  // Used by Pendo
  action: 'change',
  ctaText: 'Try CI',
  planId: 'PLA780124792f0c4e36b1dd92fff2158f23',
  trial: true,
  productType: 'conversation_intelligence',
  successAlert: `You’ve successfully added Conversation Intelligence to your account. See your current plan and active trials on your`,
  successLinkText: 'Billing page.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: true,
  externalLink: false,
};

const addCIDefault: AlterPlanAction = {
  action: 'change',
  ctaText: 'Start My Free Trial',
  planId: 'PLA780124792f0c4e36b1dd92fff2158f23',
  trial: false,
  productType: 'conversation_intelligence',
  modalGraphic: 'form-capture-upsell.svg',
  modalGraphicWhiteLabel: 'form-capture-upsell-wl.svg',
  modalTitle: 'Try Conversation Intelligence?',
  modalBodyText:
    'Add Conversation Intelligence to access conversation intelligence data for calls and texts. After your 14-day free trial, the base price of this plan is $50 per month.',
  modalCancelEvent: ['Cross Sell', 'Upgrade (pre-trial)', 'Cancel'],
  modalConfirmEvent: ['Cross Sell', 'Upgrade (pre-trial)', 'Confirm'],
  successAlert: `You’ve successfully added the Conversation Intelligence! Billing will start after your free trial ends in 14 days. To learn more about Conversation Intelligence, visit this`,
  successLinkText: 'support article',
  successLinkRoute:
    'https://support.callrail.com/hc/en-us/articles/5712030481165-Getting-started-with-Conversation-Intelligence',
  externalLink: true,
  skipModal: false,
};

const addAnalyticsDefault: AlterPlanAction = {
  action: 'change',
  ctaText: 'Start My Free Trial',
  planId: [
    'PLA780124792f0c4e36b1dd92fff2158f23',
    'PLA1fec8fd85ba547a2bac0938e3aeaa0bd',
  ],
  trial: false,
  productType: 'forms',
  modalGraphic: 'form-capture-upsell.svg',
  modalGraphicWhiteLabel: 'form-capture-upsell-wl.svg',
  modalTitle: 'Try Form Tracking and',
  modalSubTitle: 'Conversation Intelligence?', // Force very long title into two lines
  modalBodyText:
    'Unlock Form Tracking and Conversation Intelligence, bringing you the complete buyer journey and automated call transcripts, free for 14 days. After your trial ends, you’ll see an additional base charge of $100 on your invoice for the two new products.',
  modalCancelEvent: ['Cross Sell', 'Upgrade (pre-trial)', 'Cancel'],
  modalConfirmEvent: ['Cross Sell', 'Upgrade (pre-trial)', 'Confirm'],
  successAlert: `You’ve successfully added the Analytics Suite! Billing will start after your free trial ends in 14 days.`,
  skipModal: false,
  externalLink: false,
};

const addAnalyticsWithoutModal: AlterPlanAction = {
  action: 'change',
  ctaText: 'Start My Free Trial',
  planId: [
    'PLA780124792f0c4e36b1dd92fff2158f23',
    'PLA1fec8fd85ba547a2bac0938e3aeaa0bd',
  ],
  trial: true,
  productType: 'forms',
  successAlert: `You've successfully upgraded to the Analytics Suite and Form Tracking and Conversation Intelligence have been added to your account. You’ll be billed $100 a month after your free trial ends. To make changes to your account, visit`,
  successLinkText: 'Billing.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: true,
  externalLink: false,
};

const removeCIDefault: AlterPlanAction = {
  action: 'remove',
  productType: 'conversation_intelligence',
  modalTitle: 'Remove Conversation Intelligence?',
  modalBodyText:
    'Removing Conversation Intelligence will disable all features related to your call transcriptions and automation rules.',
  successAlert: `You've successfully cancelled your Conversation Intelligence plan.`,
  redirectRoute: '/account/billing',
  redirectRouteApp: 'settings',
  externalLink: false,
  trial: false,
  planId: null,
};

const addProdCI20PercentOff: AlterPlanAction = {
  // Used by Pendo
  action: 'change',
  planId: 'PLA074f0a9c7f204392a1d3fa81722433c2',
  trial: false,
  productType: 'conversation_intelligence',
  successAlert: `You’ve now upgraded to the Analytics Suite and Conversation Intelligence has been added to your account. You’ll be billed $40 for these next three months for these tools. To make changes to your account, visit`,
  successLinkText: 'Billing.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: true,
  externalLink: false,
};

const addStagingCI20PercentOff: AlterPlanAction = {
  // Used by Pendo
  action: 'change',
  planId: 'PLA0b4335f4cc724e798d0b43280f36ffd5',
  trial: false,
  productType: 'conversation_intelligence',
  successAlert: `You’ve now upgraded to the Analytics Suite and Conversation Intelligence has been added to your account. You’ll be billed $40 for these next three months for these tools. To make changes to your account, visit`,
  successLinkText: 'Billing.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: true,
  externalLink: false,
};

const addProdForms20PercentOff: AlterPlanAction = {
  // Used by Pendo
  action: 'change',
  planId: 'PLA85e08c559fbb46d6821d9386f2aa1f73',
  trial: false,
  productType: 'forms',
  successAlert: `You’ve upgraded to the Analytics Suite and Form Tracking has been added to your account. You’ll be billed $40 for the next three months for these tools. To make changes to your account, visit`,
  successLinkText: 'Billing.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: true,
  externalLink: false,
};

const addStagingForms20PercentOff: AlterPlanAction = {
  // Used by Pendo
  action: 'change',
  planId: 'PLA860b5d8479bb4f52bc0c2163819863e6',
  trial: false,
  productType: 'forms',
  successAlert: `You’ve upgraded to the Analytics Suite and Form Tracking has been added to your account. You’ll be billed $40 for the next three months for these tools. To make changes to your account, visit`,
  successLinkText: 'Billing.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: true,
  externalLink: false,
};

const addProdFormsAndCI20PercentOff: AlterPlanAction = {
  // Used by Pendo
  action: 'change',
  planId: [
    'PLA074f0a9c7f204392a1d3fa81722433c2',
    'PLA85e08c559fbb46d6821d9386f2aa1f73',
  ],
  trial: false,
  productType: 'forms',
  successAlert: `You’ve now upgraded to the Analytics Suite, and Form Tracking and Conversation Intelligence has been added to your account. You’ll be billed $80 for these next three months for these tools. To make changes to your account, visit`,
  successLinkText: 'Billing.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: true,
  externalLink: false,
};

const addStagingFormsAndCI20PercentOff: AlterPlanAction = {
  // Used by Pendo
  action: 'change',
  planId: [
    'PLA0b4335f4cc724e798d0b43280f36ffd5',
    'PLA860b5d8479bb4f52bc0c2163819863e6',
  ],
  trial: false,
  productType: 'forms',
  successAlert: `You’ve now upgraded to the Analytics Suite, and Form Tracking and Conversation Intelligence has been added to your account. You’ll be billed $80 for these next three months for these tools. To make changes to your account, visit`,
  successLinkText: 'Billing.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: true,
  externalLink: false,
};

const addLCEarlyAccessPremiumDefault: AlterPlanAction = {
  // Used by Pendo
  action: 'change',
  planId: 'PLA14fffa6f1dcd493bb66752503f1c87a3',
  productType: 'lead_center',
  successAlert: `You’ve successfully added Lead Center to your account. See your current plan and active trials on your`,
  successLinkText: 'Billing page.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: true,
  externalLink: false,
  trial: false,
};

const pciUpsellDefault: AlterPlanAction = {
  action: 'change',
  planId: 'PLA262356c6cd5044cda487042d88297b1f',
  productType: 'conversation_intelligence',
  successAlert: `You’ve successfully upgraded to Premium Conversation Intelligence. For more information, visit your`,
  successLinkText: 'billing page.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: true,
  externalLink: false,
  trial: true,
};

const convertAssistDefault = {
  action: 'change',
  productType: 'convert_assist',
  successLinkText: 'billing page.',
  successLinkRoute: '/account/billing',
  successLinkApp: 'settings',
  skipModal: true,
  externalLink: false,
  trial: true,
} as const;

export const alterPlanActionData = {
  addCallsTrial: tryCallsDefault,
  addCallsTrialWithoutLink: {
    ...tryCallsDefault,
    successAlert: `You've successfully activated your free Calls Essentials trial.`,
    successLinkText: null,
    successLinkRoute: null,
  },
  addCallsTrialTrackingSection: {
    ...tryCallsDefault,
    modalTitle: 'Try Calls & Texts?',
    modalBodyText:
      'Start your free 14-day Essentials trial to unlock valuable attribution data for calls and text messages. ' +
      'After your trial ends, your account will be auto-charged for $45/month unless you cancel or change your plan.',
  },
  addCallsPaid: addPaidCallsDefault,
  addCallsPaidWithoutLink: {
    ...addPaidCallsDefault,
    successAlert: `You've successfully activated calls and texts.`,
    successLinkText: null,
    successLinkRoute: null,
  },
  addPaidForms: {
    ...addPaidFormsDefault,
    ctaText: 'Try Form Tracking',
    modalTitle: 'Understand the entire customer journey with Form Tracking',
    modalBodyText:
      'Connect each ad, campaign, and keyword to every form submission. Get real-time alerts to follow up with customers as soon as they submit a form on your website. Form Tracking is $50/month.',
  },
  addPaidFormsWithoutLink: {
    ...addPaidFormsDefault,
    successAlert: `You've successfully added Form Tracking.`,
    successLinkText: null,
    successLinkRoute: null,
  },
  addPaidFormsFormBuilder: {
    ...addPaidFormsDefault,
    modalTitle: 'Unlock more customizations and reporting',
    modalBodyText:
      'Upgrade to unlock more styling options for your forms. You’ll also gain lead attribution reporting and tracking for 1,000 form submissions. The base price of this plan is $50 per month after your trial ends.',
    modalCancelEvent: ['Cross Sell', 'Form Builder - Edit Styles', 'Cancel'],
    modalConfirmEvent: ['Cross Sell', 'Form Builder - Edit Styles', 'Confirm'],
  },
  addPaidFormsSectionTitle: {
    ...addPaidFormsDefault,
    modalCancelEvent: [
      'Cross Sell',
      'Upgrade Circle Arrow (Lite, post-trial)',
      'Cancel',
    ],
    modalConfirmEvent: [
      'Cross Sell',
      'Upgrade Circle Arrow (Lite, post-trial)',
      'Confirm',
    ],
  },
  addPaidFormsAccountBilling: {
    ...addPaidFormsDefault,
    successAlert: `You've successfully added Form Tracking.`,
    successLinkText: null,
    successLinkRoute: null,
    modalCancelEvent: ['Cross Sell', 'Upgrade (post-trial)', 'Cancel'],
    modalConfirmEvent: ['Cross Sell', 'Upgrade (post-trial)', 'Confirm'],
  },
  addPaidFormsRedirectSettings: {
    ...addPaidFormsDefault,
    successAlert: `You've successfully upgraded to Form Tracking. For more information, visit`,
    successLinkText: 'Billing.',
    successLinkRoute: '/account/billing',
    successLinkApp: 'settings',
    redirectRoute: '/settings/integrations',
    redirectRouteApp: 'settings',
  },
  tryFormsEssentials: {
    ...tryFormsDefault,
    modalTitle: 'Try Form Tracking?',
    modalCancelEvent: [
      'Cross Sell',
      'Upgrade Circle Arrow (Lite, pre-trial)',
      'Cancel',
    ],
    modalConfirmEvent: [
      'Cross Sell',
      'Upgrade Circle Arrow (Lite, pre-trial)',
      'Confirm',
    ],
  },
  addPaidFormsCPL: {
    ...addPaidFormsDefault,
    modalBodyText:
      'Add Form Tracking to access cost per lead reporting for calls, texts, and form submissions. After your trial ends, the base price is $50 per month.',
    modalCancelEvent: ['Cross Sell', 'CPL Upgrade / Forms', 'Cancel'],
    modalConfirmEvent: ['Cross Sell', 'CPL Upgrade / Forms', 'Confirm'],
  },
  tryForms: {
    ...tryFormsDefault,
    ctaText: 'Try Form Tracking',
    modalTitle: 'Understand the entire customer journey with Form Tracking',
    modalBodyText:
      'Connect each ad, campaign, and keyword to every form submission. Get real-time alerts to follow up with customers as soon as they submit a form on your website. Form Tracking is $50/month.',
    redirectRoute: '/routing/cr-forms/custom-forms',
    redirectRouteApp: 'settings',
  },
  tryFormsFormBuilder: {
    ...tryFormsDefault,
    modalCancelEvent: ['Cross Sell', 'Form Builder - Edit Styles', 'Cancel'],
    modalConfirmEvent: ['Cross Sell', 'Form Builder - Edit Styles', 'Confirm'],
  },
  tryFormsCPL: {
    ...tryFormsDefault,
    modalBodyText:
      'Add Form Tracking to access cost per lead reporting for calls, texts, and form submissions. Add it to your existing plan for $50 per month.',
    modalCancelEvent: ['Cross Sell', 'CPL Upgrade / Forms', 'Cancel'],
    modalConfirmEvent: ['Cross Sell', 'CPL Upgrade / Forms', 'Confirm'],
  },
  tryFormTrackingUpsell: {
    ...tryFormsDefault,
    skipModal: true,
    successAlert: `Form Tracking has been activated for your account.`,
    successLinkText: null,
    successLinkRoute: null,
    redirectRoute: '/reports/form-submission-list',
    redirectRouteApp: 'analytics',
  },
  tryFormsWithoutLink: {
    // DONT REMOVE OR CHANGE THIS ONE, IT IS USED EXTERNALLY ON MARKETING SITE REDIRECTS
    ...tryFormsDefault,
    modalTitle: 'Try Form Tracking?',
    modalBodyText:
      'Add the Form Tracking product for form tracking and advanced multi-channel reporting features. The base price of this plan is $50 per month.',
    modalCancelEvent: ['Cross Sell', 'Upgrade (pre-trial)', 'Cancel'],
    modalConfirmEvent: ['Cross Sell', 'Upgrade (pre-trial)', 'Confirm'],
    successAlert: `You've successfully activated your free Form Tracking trial.`,
    successLinkText: null,
    successLinkRoute: null,
  },
  tryFormsWithoutModal: {
    // Used by Pendo
    ...tryFormsWithoutModal,
  },
  tryFormsWithoutModal30Days: {
    // Used by Pendo
    ...tryFormsWithoutModal,
    customExpiration: '30DayTrial',
  },
  tryFormsEssentialsAccountBilling: {
    ...tryFormsDefault,
    modalTitle: 'Try Form Tracking?',
    modalBodyText:
      'Add the Form Tracking product for form tracking and advanced multi-channel reporting features. The base price of this plan is $50 per month.',
    modalCancelEvent: ['Cross Sell', 'Upgrade (pre-trial)', 'Cancel'],
    modalConfirmEvent: ['Cross Sell', 'Upgrade (pre-trial)', 'Confirm'],
    successAlert: `You've successfully activated your free Form Tracking trial.`,
    successLinkText: null,
    successLinkRoute: null,
  },
  tryFormsEssentialsRedirectSettings: {
    ...tryFormsDefault,
    successAlert: `You've successfully upgraded to Form Tracking Trial. For more information, visit`,
    successLinkText: 'Billing.',
    successLinkRoute: '/account/billing',
    redirectRoute: '/settings/integrations',
    redirectRouteApp: 'settings',
  },
  cancelCallsTrial: {
    action: 'remove',
    productType: 'calls',
    modalTitle: 'Cancel Calls & Texts Trial?',
    modalBodyText:
      'Canceling your calls and texts trial will permanently release all tracking numbers.',
    successAlert: `You've successfully cancelled your calls and texts trial.`,
    redirectRoute: '/account/billing',
    redirectRouteApp: 'settings',
    trial: false,
    planId: null,
  },
  cancelCallsPaid: {
    action: 'remove',
    productType: 'calls',
    modalTitle: 'Cancel Calls & Texts Plan?',
    modalBodyText:
      'Canceling your calls and texts plan will permanently release all tracking numbers.',
    successAlert: `You've successfully cancelled your calls and texts plan.`,
    trial: false,
    planId: null,
  },
  tryCIWithoutLink: {
    ...addCIDefault,
  },
  tryAnalyticsWithoutLink: {
    ...addAnalyticsDefault,
  },
  tryAnalyticsWithoutModal: {
    ...addAnalyticsWithoutModal,
  },
  cancelConversationIntelligence: {
    ...removeCIDefault,
  },
  tryConversationIntelligence: {
    // Used by Pendo
    ...tryCIWithoutModal,
  },
  tryConversationIntelligence30Days: {
    // Used by Pendo
    ...tryCIWithoutModal,
    customExpiration: '30DayTrial',
  },
  addCI20PercentProd: {
    ...addProdCI20PercentOff,
  },
  addCI20PercentStaging: {
    ...addStagingCI20PercentOff,
  },
  addForms20PercentProd: {
    ...addProdForms20PercentOff,
  },
  addForms20PercentStaging: {
    ...addStagingForms20PercentOff,
  },
  addFormsAndCI20PercentProd: {
    ...addProdFormsAndCI20PercentOff,
  },
  addFormsAndCI20PercentStaging: {
    ...addStagingFormsAndCI20PercentOff,
  },
  upgradeLcEarlyAccessPremium: {
    ...addLCEarlyAccessPremiumDefault,
    trial: false,
    successAlert: `You’ve upgraded to the Lead Center Premium plan. To make changes to your account, visit`,
    successLinkText: 'Billing.',
    successLinkRoute: '/account/billing',
  },
  tryLeadCenterEAP: {
    // used by pendo
    ...addLCEarlyAccessPremiumDefault,
  },
  tryLeadCenterEssentials: {
    // used by pendo
    action: 'change',
    planId: 'PLA93da3fba42b043ee9e1590e4266aad81',
    productType: 'lead_center',
    successAlert: `You’ve successfully added Lead Center to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  tryLCEssentialsAndForms: {
    // used by pendo
    action: 'change',
    planId: [
      'PLA93da3fba42b043ee9e1590e4266aad81',
      'PLA1fec8fd85ba547a2bac0938e3aeaa0bd',
    ],
    productType: 'lead_center',
    successAlert: `You’ve successfully added Lead Center and Form Tracking to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  tryLCEssentialsAndCI: {
    // used by pendo
    action: 'change',
    planId: [
      'PLA93da3fba42b043ee9e1590e4266aad81',
      'PLA780124792f0c4e36b1dd92fff2158f23',
    ],
    productType: 'lead_center',
    successAlert: `You’ve successfully added Lead Center and Conversation Intelligence to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  tryLCEssentialsFormsAndCI: {
    // used by pendo
    action: 'change',
    planId: [
      'PLA93da3fba42b043ee9e1590e4266aad81',
      'PLA1fec8fd85ba547a2bac0938e3aeaa0bd',
      'PLA780124792f0c4e36b1dd92fff2158f23',
    ],
    productType: 'lead_center',
    successAlert: `You’ve successfully added Lead Center, Form Tracking, and Conversation Intelligence to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  // this action actually does a bunch of other things that are not obvious from the way that this is
  // written.  See `billy-alter-plan` for the custom logic that is added for this specific action.
  leadCenterLegacyTransitionOffer: {
    action: 'change',
    planId: 'PLAe138fa7371ad472c8faea489553972ca',
    productType: 'lead_center',
    successAlert: "You've successfully switched to the updated Lead Center.",
    skipModal: true,
    trial: false,
  },
  tryLeadCenterStandard: {
    // used by pendo
    action: 'change',
    planId: 'PLA014eeddfbc1f49fa9b586a228dca7c95',
    productType: 'lead_center',
    successAlert: `You’ve successfully added Lead Center to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  tryLeadCenterSpecial: {
    // used by pendo
    action: 'change',
    planId: 'PLA2c2415431bda410abd8cc2e9dae153df',
    productType: 'lead_center',
    successAlert:
      'You’ve successfully added Lead Center to your account. See your current plan and active trials on your',
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  upgradeToLCStandard: {
    // used by pendo
    action: 'change',
    planId: 'PLA014eeddfbc1f49fa9b586a228dca7c95',
    productType: 'lead_center',
    successAlert: `You've successfully upgraded your Lead Center plan. See your current plan on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    skipModal: true,
    externalLink: false,
    trial: false,
  },
  upgradeToLCPremium: {
    // used by pendo
    action: 'change',
    planId: 'PLA9251d8a2cade4f7eafa6b7471bb7ab64',
    productType: 'lead_center',
    successAlert: `You've successfully upgraded your Lead Center plan. See your current plan on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    skipModal: true,
    externalLink: false,
    trial: false,
  },
  tryLeadCenterConnect: {
    // used by pendo
    action: 'change',
    planId: 'PLAfddf9bfacb3449eaafb232feca3e32e6',
    productType: 'lead_center',
    successAlert: `You’ve successfully added Lead Center to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    successLinkApp: 'settings',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  tryLeadCenterConnect30Days: {
    // used by pendo
    action: 'change',
    planId: 'PLAfddf9bfacb3449eaafb232feca3e32e6',
    productType: 'lead_center',
    successAlert: `You’ve successfully added Lead Center to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    successLinkApp: 'settings',
    skipModal: true,
    externalLink: false,
    trial: true,
    customExpiration: '30DayTrial',
  },
  tryForms30PerMonth: {
    // used by pendo
    action: 'change',
    planId: 'PLAe4839ae5e33a42ee9e4707a5acfea7c7',
    productType: 'forms',
    successAlert: `You've successfully added Form Tracking to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    successLinkApp: 'settings',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  tryLCConnectForTeams: {
    // used by pendo
    action: 'change',
    planId: 'PLA908b9183fc8d4e06aceb3bef0a1dd592',
    productType: 'lead_center',
    successAlert: `You’ve successfully added Lead Center to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    successLinkApp: 'settings',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  tryLCConnectForTeams30Days: {
    // used by pendo
    action: 'change',
    planId: 'PLA908b9183fc8d4e06aceb3bef0a1dd592',
    productType: 'lead_center',
    successAlert: `You’ve successfully added Lead Center to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    successLinkApp: 'settings',
    skipModal: true,
    externalLink: false,
    trial: true,
    customExpiration: '30DayTrial',
  },
  addCIUnlimited40: {
    // used by pendo
    action: 'change',
    planId: 'PLA0f2d0b5133e140889723598348c91599',
    productType: 'conversation_intelligence',
    successAlert: `You’ve successfully added Conversation Intelligence to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    successLinkApp: 'settings',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  upgradeCIPremium: {
    action: 'change',
    planId: 'PLAaecd83dfa50c4216ab3ab6b4c813380a',
    productType: 'conversation_intelligence',
    successAlert: `You’ve successfully upgraded to Premium Conversation Intelligence. For more information, visit your`,
    successLinkText: 'billing page.',
    successLinkRoute: '/account/billing',
    successLinkApp: 'settings',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  fullPriceCIPremium: {
    ...pciUpsellDefault,
  },
  fullPriceCIPremiumAnnual: {
    ...pciUpsellDefault,
    planId: 'PLAed4694a2699d4827b5984adc6efe69da',
  },
  addCIPromo: {
    action: 'change',
    planId: 'PLA6dabca616d0347f0a42ee5ada9ef2964',
    productType: 'conversation_intelligence',
    successAlert: `You’ve successfully added Conversation Intelligence to your account. For more information, visit your`,
    successLinkText: 'billing page.',
    successLinkRoute: '/account/billing',
    successLinkApp: 'settings',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
  pciPremium30Days: {
    action: 'change',
    planId: 'PLA262356c6cd5044cda487042d88297b1f',
    productType: 'conversation_intelligence',
    successAlert: `You’ve successfully upgraded to Premium Conversation Intelligence. For more information, visit your`,
    successLinkText: 'billing page.',
    successLinkRoute: '/account/billing',
    successLinkApp: 'settings',
    skipModal: true,
    externalLink: false,
    trial: true,
    customExpiration: '30DayTrial',
  },
  pciUpsellCallSummaries: {
    ...pciUpsellDefault,
    redirectRouteApp: 'analytics',
    redirectRoute: '/reports/call-summary-list',
  },
  pciUpsellTranscript: {
    ...pciUpsellDefault,
    redirectRouteApp: 'analytics',
    redirectRoute: '/reports/transcription-search-call-list',
  },
  convertAssistStandard: {
    ...convertAssistDefault,
    planId: 'PLAae8b167827a84473b9caefc78c5ae724',
    successAlert: `You’ve successfully added Convert Assist. For more information, visit your`,
  },
  convertAssistStandardPromo: {
    ...convertAssistDefault,
    planId: 'PLA85cdb5d6b8b5434c8056bf17b9ba32a6',
    successAlert: `You’ve successfully added Convert Assist promo. For more information, visit your`,
  },
  convertAssistPlus: {
    ...convertAssistDefault,
    planId: 'PLAb980688fa03d45b79590dfb997bd2e9e',
    successAlert: `You’ve successfully added Convert Assist Plus. For more information, visit your`,
  },
  convertAssistPlusPromo: {
    ...convertAssistDefault,
    planId: 'PLA1d9139e2dceb4fbdb916434d77453516',
    successAlert: `You’ve successfully added Convert Assist Plus promo. For more information, visit your`,
  },
  convertAssistStandardPromo25: {
    ...convertAssistDefault,
    planId: 'PLAf1c45b05e2e74a828dcffb65c487b509',
    successAlert: `You’ve successfully added Convert Assist promo. For more information, visit your`,
  },
  formTrackingPromo40: {
    ...tryFormsWithoutModal,
    planId: 'PLA29f50c19fe79499c83c9057e9cf72a48',
  },
  convertAssistPromo25AndPCISpecialOffer: {
    action: 'change',
    planId: [
      'PLAf1c45b05e2e74a828dcffb65c487b509',
      'PLAaecd83dfa50c4216ab3ab6b4c813380a',
    ],
    productType: 'convert_assist',
    successAlert: `You’ve successfully added Convert Assist and Premium Conversation Intelligence to your account. See your current plan and active trials on your`,
    successLinkText: 'Billing page.',
    successLinkRoute: '/account/billing',
    successLinkApp: 'settings',
    skipModal: true,
    externalLink: false,
    trial: true,
  },
} satisfies Record<string, AlterPlanAction>;

export type AlterPlanActionType = keyof typeof alterPlanActionData;
